export const DOM_ID_ROOT = 'root'
export const DOM_ID_PAGE = 'page'
export const DOM_ID_BOOKMARKED_LECTURES = 'bookmarked_lectures'
export const DOM_ID_LECTURE_TABS = 'lecture_tabs'
export const DOM_ID_POINTS = 'points'
export const DOM_ID_LEARNING_OBJECTIVE_STATUS = 'learning-objective-status'
export const DOM_ID_DASHBOARD_COURSE_SPOTLIGHT = 'dashboard-course'
export const DOM_ID_DASHBOARD_RECOMMENDED_COURSE =
  'dashboard-recommended-course'

export const ENV_PRODUCTION = 'production'
export const ENV_STAGING = 'staging'
export const ENV_DEVELOPMENT = 'development'
export const ENV_TEST = 'test'

export const STATUS_LOCKED = 'locked'
export const STATUS_UNLOCKED = 'unlocked'
export const STATUS_STARTED = 'started'
export const STATUS_IN_PROGRESS = 'inProgress'
export const STATUS_VIEWED = 'viewed'
export const STATUS_COMPLETED = 'completed'

export const FETCHED = 'fetched'
export const FETCHING = 'fetching'
export const FAILED = 'failed'

export const ERROR_TYPE_GENERAL = 'ERROR_TYPE_GENERAL'
export const ERROR_TYPE_API = 'ERROR_TYPE_API'

export const REQUEST_RETRY_COUNT = 3

export const LOCAL_STORAGE_ITEMS = {
  keycloak: 'keycloak',
  masterplan: 'masterplan',
  seen_notifications: 'seen_notifications',
  video_player_params: 'video_player_params',
}

export const GAMIFICATION_AWARD_GRANTED = 'gamification-award-granted'

export const NOTIFICATIONS = { outdated_browser: 'outdated_browser' }

export const NOTIFICATION_TYPE_SUCCESS = 'success'
export const NOTIFICATION_TYPE_WARN = 'warn'
export const NOTIFICATION_TYPE_ERROR = 'error'

export const AWARD_BRONZE = 'bronze'
export const AWARD_SILVER = 'silver'
export const AWARD_GOLD = 'gold'

export const TOPIC_TYPE_CHANNEL = 'channel'
export const TOPIC_TYPE_COURSE = 'course'
export const TOPIC_TYPE_INTERNAL = 'internal'

export const REDUCER_FORM_KEY = 'form'
export const REDUCER_SIGNUP_KEY = 'signup'

export const SIGNUP_ROLE_STUDENT = 'student'
export const SIGNUP_ROLE_MANAGER = 'manager'
export const SIGNUP_STEPS_COUNT_STUDENT = 2
export const SIGNUP_STEPS_COUNT_MANAGER = 1

export const TRACK_ANALYTICS_DASHBOARD_PAGE_NAVIGATED =
  'Analytics dashboard page navigated'
export const TRACK_ANALYTICS_DASHBOARD_FILTER_USED =
  'Analytics dashboard filter used'
export const TRACK_ANALYTICS_DASHBOARD_REPORT_DOWNLOADED =
  'Analytics dashboard report downloaded'
export const TRACK_ADDITIONAL_MATERIALS_DOWNLOAD =
  'Additional materials download'
export const TRACK_CERTIFICATE_DOWNLOAD = 'Certificate downloaded'
export const TRACK_DASHBOARD_SEARCH_QUERY = 'Dashboard search query'
export const TRACK_DASHBOARD_SEARCH_RESULTS = 'Dashboard search results'
export const TRACK_EXIT = 'Exit'
export const TRACK_FORM = 'Form'
export const TRACK_LANGUAGE_CHANGE = 'Language change'
export const TRACK_LEARNPATH_LINK_SHARING_ACTIVATED =
  'Learnpath link sharing activated'
export const TRACK_LEARNPATH_LINK_SHARING_DEACTIVATED =
  'Learnpath link sharing deactivated'
export const TRACK_LECTURE_CONTINUE = 'Lecture continue'
export const TRACK_LECTURE_END = 'Lecture end'
export const TRACK_LECTURE_FEEDBACK = 'Lecture feedback'
export const TRACK_LECTURE_QUIZ_END = 'Quiz end'
export const TRACK_LECTURE_QUIZ_QUESTION_ANSWERED = 'Quiz question answered'
export const TRACK_LECTURE_QUIZ_START = 'Quiz start'
export const TRACK_LECTURE_RESTART = 'Lecture restart'
export const TRACK_LECTURE_START = 'Lecture start'
export const TRACK_NAVIGATION = 'Navigation'
export const TRACK_PROP_SIGNUP_COMPLETED = 'Onboarding completed'
export const TRACK_PROP_SIGNUP_METHOD = 'Signup method'
export const TRACK_SIGNUP_START = 'Signup start'
export const TRACK_SIGNUP_START_TYPE_EMAIL = 'Email'
export const TRACK_TEXT_LECTURE_LINK_CLICK = 'Text lecture link click'
export const TRACK_NOTIFICATION_BELL_CLICKED = 'Notification bell clicked'
export const TRACK_NOTIFICATION_MARK_AS_READ_CLICKED =
  'Notification mark as read clicked'

export const POLL_TYPE_SIGNUP = 'signup'
export const POLL_TYPE_CERTIFICATE = 'certificate'
export const POLL_TYPE_LECTURE = 'lecture'

export const POLL_QUESTION_TYPE_RATE = 'rate'
export const POLL_QUESTION_TYPE_BOOL = 'bool'
export const POLL_QUESTION_TYPE_CHOOSE = 'choose'
export const POLL_QUESTION_TYPE_FREE_TEXT = 'free_text'
export const POLL_QUESTION_TYPE_SELECT = 'select'

export const COLOR_PRIMARY = '#fc6676'
export const COLOR_MP_GRAPHITE = '#1f2124'
export const COLOR_SCHOOL_BUS_YELLOW = '#ffd800'

export const BOOKMARK_TYPE_VIDEO = 'video'
export const BOOKMARK_TYPE_HIGHLIGHT = 'highlight'

export const USER_TITLE_MALE = 'mr'
export const USER_TITLE_FEMALE = 'mrs'
export const USER_TITLE_NONBINARY = 'mx'
export const USER_TITLES = [
  USER_TITLE_MALE,
  USER_TITLE_FEMALE,
  USER_TITLE_NONBINARY,
]
export const USER_ROLES = {
  owner: 'owner',
  admin: 'admin',
  company_administrator: 'company_administrator',
  manager: 'manager',
  student: 'student',
}

export const IMAGE_ACCEPTED_MIME_TYPES = ['image/jpeg', 'image/png']
export const IMAGE_MAX_UPLOAD_SIZE = 3 * 1024 * 1024

export const URL_ROOT = '/'

export const URL_ACHIEVEMENTS = '/achievements'
export const URL_GLOSSARY = '/glossary'
export const URL_LOGIN = '/login'

export const URL_USER = '/user'
export const URL_USER_SETTINGS = '/user/settings/:tab?'
export const URL_USER_PROGRESS = '/user/progress'

export const URL_COURSE = '/course'
export const URL_DASHBOARD_COURSE = '/home-course'

export const URL_COMPANY = '/company'
export const URL_COMPANY_ANALYTICS_TAB = '/company/analytics/:tab?'
export const URL_COMPANY_ACTIVITIES = '/company/activities'
export const URL_COMPANY_KPI = '/company/kpi'
export const URL_COMPANY_KPI_LEARNPATHS = '/company/kpi/learnpaths'
export const URL_COMPANY_SETTINGS = '/company/settings'
export const URL_COMPANY_LEARNPATHS = '/company/learnpaths'
export const URL_COMPANY_LEARNPATHS_OVERVIEW = '/company/learnpaths/overview'
export const URL_COMPANY_LEARNPATHS_CREATOR = '/company/learnpaths/add'
export const URL_COMPANY_LEARNPATHS_CREATOR_START =
  '/company/learnpaths/add/general-information'
export const URL_COMPANY_LEARNPATHS_CREATOR_UPDATE = `/company/learnpaths/add/*/:learnpathId`

export const URL_COMPANY_MEMBERS_GROUPS = '/company/members/groups'
export const URL_COMPANY_MEMBERS_GROUPS_ADD =
  '/company/members/groups/add-group'
export const URL_COMPANY_MEMBERS_GROUPS_DELETE =
  '/company/members/groups/delete-group/:groupId'
export const URL_COMPANY_MEMBERS_GROUPS_EDIT =
  '/company/members/groups/edit-group/:groupId'
export const URL_COMPANY_MEMBERS_GROUPS_MEMBERS =
  '/company/members/groups/members/:groupId'
export const URL_COMPANY_IMPORT_USERS = '/company/import-users'
export const URL_COMPANY_IMPORT_USERS_ADD = '/company/import-users/upload'
export const URL_COMPANY_USERS = '/company/users/:userId?'
export const URL_COMPANY_INVITE = '/company/invite/:role?'
export const URL_CORE_SKILLS_ASSESSMENT_DEPRECATED = '/core-skills-assessment'
export const URL_CORE_SKILLS_ASSESSMENT = '/skills-assessment'
export const URL_COMPANY_EVENTS_LIST = '/company/events/overview'
export const URL_COMPANY_EVENTS_CREATOR = '/company/events/add'
export const URL_COMPANY_COURSES_LIST = '/company/courses/overview'
export const URL_COMPANY_COURSES_CREATOR_START =
  '/company/learnpaths/add/general-information-course'

export const URL_NO_EMAIL_LOGIN = '/login/:ssoSourceId'
export const URL_NO_EMAIL_REGISTER = '/registration/:ssoSourceId'

export const URL_DASHBOARD = '/home'
export const URL_DASHBOARD_BOOKMARKED = '/home/bookmarked'
export const URL_DASHBOARD_VIEW = '/home/view'
export const URL_DASHBOARD_NOTES = '/home/notes'
export const URL_DASHBOARD_ACTIVITIES = '/home/activities'

export const URL_DASHBOARD_OLD = '/dashboard'

export const URL_SIGNUP = '/signup'
export const URL_SIGNUP_WELCOME = '/signup/welcome'
export const URL_SIGNUP_EMAIL = '/signup/email'
export const URL_SIGNUP_CREDENTIALS = '/signup/credentials'
export const URL_SIGNUP_PROFILE = '/signup/profile'
export const URL_SIGNUP_PROFILE_AVATAR = '/signup/profile/avatar'
export const URL_SIGNUP_POLL = '/signup/poll'
export const URL_SIGNUP_SELF = '/signup/:slug'

export const URL_LECTURES = '/lectures'
export const URL_LECTURES_PARAMS_LECTURE_PARAMS = '/lectures/*'

export const URL_LEARNGROUP = '/learngroup'

export const URL_CONSENTS = '/consents/:consentKey'

export const URL_LEARNPATHS_AS_COURSES = '/company-courses'
export const URL_LEARNPATHS_AS_COURSES_ALL = '/company-courses/*'
export const URL_LEARNPATHS_AS_COURSES_VIEW = '/company-courses/view'
export const URL_LEARNPATHS_AS_COURSES_VIEW_LEARNPATH =
  '/company-courses/view/:learnpathId'
export const URL_LEARNPATHS_AS_COURSES_VIEW_PARAMS =
  '/company-courses/view/:learnpathId/:elementId?'
export const URL_LEARNPATHS = '/learnpaths'
export const URL_LEARNPATHS_ALL = '/learnpaths/*'
export const URL_LEARNPATHS_VIEW = '/learnpaths/view'
export const URL_LEARNPATHS_VIEW_LEARNPATH = '/learnpaths/view/:learnpathId'
export const URL_LEARNPATHS_VIEW_PARAMS =
  '/learnpaths/view/:learnpathId/:elementId?/:topic?'

export const URL_TOPIC = '/courses/:topic'

export const URL_DISCOVER = '/discover'
export const URL_DISCOVER_VIEW_CHANNEL_TOPIC = '/discover/view/:channel/:topic'
export const URL_DISCOVER_CHANNEL = '/discover/:channel'
export const URL_DISCOVER_CHANNEL_VIEW_TOPIC = '/discover/:channel/view/:topic'
export const URL_DISCOVER_SEARCH = '/discover/search'
export const URL_DISCOVER_SEARCH_RESULT_VIEW =
  '/discover/search/:query/view/:topic'

export const URL_POLL_PAGE = '/poll/:pollId/:source?/:sourceId?'

export const URL_GROWTH_MAP = '/growth-map'

export const URL_SKILL = '/skill'
export const URL_SKILL_TOPIC = '/skill/:skill/:topic'
export const URL_SKILL_LECTURE = '/skill/:skill/:topic/:lecture'
export const URL_SKILL_LECTURE_SUFFIX = '/skill/:skill/:topic/:lecture/:suffix'

export const URL_COURSE_GENERIC = '/course-generic'
export const URL_COURSE_GENERIC_TOPIC = '/course-generic/:course/:topic'
export const URL_COURSE_GENERIC_LECTURE =
  '/course-generic/:course/:topic/:lecture'
export const URL_COURSE_GENERIC_LECTURE_SUFFIX =
  '/course-generic/:course/:topic/:lecture/:suffix'

export const BASIC_COURSE_SLUG = 'digital-transformation'
export const AI_COURSE_SLUG = 'ai-fundamentals'

export const CERTIFICATE_STEP_PERSONALIZE = 'personalize'
export const CERTIFICATE_STEP_POLL = 'poll'

export const CERTIFICATE_DOWNLOAD_STATUS_NOT_REQUESTED = 'not_requested'
export const CERTIFICATE_DOWNLOAD_STATUS_PROCESSING = 'processing'
export const CERTIFICATE_DOWNLOAD_STATUS_AVAILABLE = 'available'
export const CERTIFICATE_DOWNLOAD_STATUS_ERROR = 'error'

export const CERTIFICATE_CONGRATULATIONS_PARAM = 'certificate-congratulations'

export const URL_CERTIFICATE = '/certificate'
export const URL_CERTIFICATE_PERSONALIZE = `/certificate/${CERTIFICATE_STEP_PERSONALIZE}`
export const URL_CERTIFICATE_POLL = `/certificate/${CERTIFICATE_STEP_POLL}?step=1`
export const URL_CERTIFICATE_CONGRATULATIONS = `/home?${CERTIFICATE_CONGRATULATIONS_PARAM}`

export const URL_CERTIFICATES = '/certificates'

export const URL_SUFFIX_BOOKMARKS = 'bookmarks'
export const URL_SUFFIX_DEFINITIONS = 'definitions'
export const URL_SUFFIX_DOWNLOADS = 'downloads'
export const URL_SUFFIX_INFO = 'info'
export const URL_SUFFIX_LESSONS = 'lessons'
export const URL_SUFFIX_NOTES = 'notes'
export const URL_SUFFIX_FEEDBACK = 'feedback'
export const URL_SUFFIX_QUIZ = 'quiz'
export const URL_SUFFIX_TRANSCRIPT = 'transcript'

export const URL_PASSWORD_RESET = '/password-reset'

export const URL_IMPRESSUM = '/impressum'

export const API_URL_COURSE = '/course'
export const API_URL_POLL_SIGNUP = '/polls/signup'
export const API_URL_LEARNGROUP = '/learngroup'
export const API_URL_LEARNGROUP_SCORING = '/learngroup/scoring'
export const API_URL_LEARNGROUP_LEADERBOARD = '/learngroup/leaderboard'
export const API_URL_LEARNGROUP_MEMBERSHIPS = '/learngroup/memberships'

export const API_URL_INVITES = '/users'
export const API_URL_SELF_INVITE = '/invites/self-invite'

export const API_ERROR_USER_AVATAR_REJECTED =
  'user.error.avatar-upload-rejected-file'
export const API_ERROR_USER_AVATAR_TOO_BIG = 'user.error.avatar-upload-too-big'

export const API_ERROR_SELF_SIGNUP_LINK_NOT_FOUND =
  'invite.error.self-invite-link-not-found'

export const API_URL_CERTIFICATE = '/course/certificate'
export const API_URL_CERTIFICATE_DOWNLOAD = '/course/certificate/download'

export const API_ERROR_CERTIFICATE_NOT_FOUND =
  'course.error.user-certificate-not-found'
export const API_ERROR_CERTIFICATE_NOT_FILLED =
  'course.error.user-certificate-not-filled-out'

export const API_URL_CERTIFICATES = '/certificates'

export const API_ERROR_LEARNGROUP_NOT_FOUND =
  'learngroup.error.no-user-learngroup'

export const API_ERROR_LECTURE_FEEDBACK_NOT_FOUND =
  'lecture.error.user-feedback-not-found'

export const API_ERROR_USER_UNAUTHORIZED = 'api.error.unauthorized'
export const API_ERROR_FIELD_IS_NOT_A_VALID_URL =
  'api.error.field-is-not-a-valid-url'

export const API_ERROR_FEATURE_DISABLED = 'api.error.feature-disabled'

export const SCORM_ACTION_AUTH = 'AUTH'
export const SCORM_ACTION_INITIALIZE = 'INITIALIZE'
export const SCORM_ACTION_TERMINATE = 'TERMINATE'
export const SCORM_ACTION_GET = 'GET'
export const SCORM_ACTION_SET = 'SET'
export const SCORM_ACTION_COMMIT = 'COMMIT'

export const CHANNEL_TOPICS_DISPLAY_ORDER = [
  STATUS_STARTED,
  STATUS_UNLOCKED,
  STATUS_COMPLETED,
]

export const THEME_DARK = 'dark'
export const THEME_LIGHT = 'light'

export const FIELDS_OF_ACTIVITY_MANAGEMENT = 'management'
export const FIELDS_OF_ACTIVITY_PROCUREMENT = 'procurement'
export const FIELDS_OF_ACTIVITY_SALES = 'sales'
export const FIELDS_OF_ACTIVITY_FINANCE = 'finance'
export const FIELDS_OF_ACTIVITY_IT = 'it'
export const FIELDS_OF_ACTIVITY_BUSINESS_INTELLIGENCE = 'business_intelligence'
export const FIELDS_OF_ACTIVITY_CUSTOMER_CARE = 'customer_care'
export const FIELDS_OF_ACTIVITY_LOGISTICS = 'logistics'
export const FIELDS_OF_ACTIVITY_HUMAN_RESOURCES = 'human_resources'
export const FIELDS_OF_ACTIVITY_CONSULTING = 'consulting'
export const FIELDS_OF_ACTIVITY_COACHING = 'coaching'
export const FIELDS_OF_ACTIVITY_PRODUCT_DEVELOPMENT = 'product_development'
export const FIELDS_OF_ACTIVITY_GRAPHIC_DESIGN = 'graphic_design'
export const FIELDS_OF_ACTIVITY_ACCOUNTING = 'accounting'
export const FIELDS_OF_ACTIVITY_LEGAL = 'legal'
export const FIELDS_OF_ACTIVITY_ADMINISTRATION = 'administration'
export const FIELDS_OF_ACTIVITY_MARKETING = 'marketing'
export const FIELDS_OF_ACTIVITY_PUBLIC_RELATIONS = 'public_relations'
export const FIELDS_OF_ACTIVITY_RESEARCH_DEVELOPMENT = 'research_development'
export const FIELDS_OF_ACTIVITY_QUALITY_MANAGEMENT = 'quality_management'
export const FIELDS_OF_ACTIVITY_EDITORIAL = 'editorial'
export const FIELDS_OF_ACTIVITY_E_COMMERCE = 'e_commerce'
export const FIELDS_OF_ACTIVITY_PRODUCTION = 'production'
export const FIELDS_OF_ACTIVITY_WAREHOUSE = 'warehouse'
export const FIELDS_OF_ACTIVITY_OTHER = 'other'

export const FIELDS_OF_ACTIVITY = [
  FIELDS_OF_ACTIVITY_MANAGEMENT,
  FIELDS_OF_ACTIVITY_PROCUREMENT,
  FIELDS_OF_ACTIVITY_SALES,
  FIELDS_OF_ACTIVITY_FINANCE,
  FIELDS_OF_ACTIVITY_IT,
  FIELDS_OF_ACTIVITY_BUSINESS_INTELLIGENCE,
  FIELDS_OF_ACTIVITY_CUSTOMER_CARE,
  FIELDS_OF_ACTIVITY_LOGISTICS,
  FIELDS_OF_ACTIVITY_HUMAN_RESOURCES,
  FIELDS_OF_ACTIVITY_CONSULTING,
  FIELDS_OF_ACTIVITY_COACHING,
  FIELDS_OF_ACTIVITY_PRODUCT_DEVELOPMENT,
  FIELDS_OF_ACTIVITY_GRAPHIC_DESIGN,
  FIELDS_OF_ACTIVITY_ACCOUNTING,
  FIELDS_OF_ACTIVITY_LEGAL,
  FIELDS_OF_ACTIVITY_ADMINISTRATION,
  FIELDS_OF_ACTIVITY_MARKETING,
  FIELDS_OF_ACTIVITY_PUBLIC_RELATIONS,
  FIELDS_OF_ACTIVITY_RESEARCH_DEVELOPMENT,
  FIELDS_OF_ACTIVITY_QUALITY_MANAGEMENT,
  FIELDS_OF_ACTIVITY_EDITORIAL,
  FIELDS_OF_ACTIVITY_E_COMMERCE,
  FIELDS_OF_ACTIVITY_PRODUCTION,
  FIELDS_OF_ACTIVITY_WAREHOUSE,
  FIELDS_OF_ACTIVITY_OTHER,
]

export const RATING_MAX = 10

export const INTERNAL_EMAIL_DOMAIN = '@masterplan.com'

export const LECTURE_PARAM_TIME = 't'

export const SENTRY_LEVELS = {
  debug: 'debug',
  info: 'info',
  warning: 'warning',
  error: 'error',
  fatal: 'fatal',
}
export const SENTRY_SECTIONS = {
  eventTracking: 'event-tracking',
  keycloak: 'keycloak',
  learnpath: 'learnpath',
}

export const KEYCLOAK_EVENT_ON_READY = 'onReady'
export const KEYCLOAK_EVENT_ON_INIT_ERROR = 'onInitError'
export const KEYCLOAK_EVENT_ON_AUTH_SUCCESS = 'onAuthSuccess'
export const KEYCLOAK_EVENT_ON_AUTH_ERROR = 'onAuthError'
export const KEYCLOAK_EVENT_ON_AUTH_REFRESH_SUCCESS = 'onAuthRefreshSuccess'
export const KEYCLOAK_EVENT_ON_AUTH_REFRESH_ERROR = 'onAuthRefreshError'
export const KEYCLOAK_EVENT_ON_TOKEN_EXPIRED = 'onTokenExpired'
export const KEYCLOAK_EVENT_ON_AUTH_LOGOUT = 'onAuthLogout'

export const PLAYER_PLUGIN_TYPE_BOOKMARK = 'bookmark-plugin'
export const PLAYER_PLUGIN_TYPE_PROGRESS = 'progress-plugin'
export const PLAYER_PLUGIN_POSITION_RIGHT = 'right'
export const PLAYER_PLUGIN_POSITION_BOTTOM_INSIDE = 'bottom_inside'

export const MINUTE = 60_000
export const HOUR = 3_600_000
export const DAY = 86_400_000

export const DATE_FORMAT = 'yyyy-MM-dd'
// The earliest date from which data is collected for the Analytics Dashboard
export const METRICS_MIN_DATE = '2022-01-01'

export const CLOUDFLARE_STREAM_DOMAIN = 'cloudflarestream.com'
export const YOUTUBE_DOMAIN = 'youtube.com'

export const VIDEO_PLAYBACK_RATE_KEY = 'playbackrate'
export const VIDEO_QUALITY_KEY = 'quality'
export const VIDEO_VOLUME_KEY = 'volume'
export const VIDEO_MUTE_KEY = 'mute'
export const VIDEO_CAPTIONS_KEY = 'captions'

export const THUMBNAIL_ID_DEFAULT_EVENT =
  'system/learnpath/element-event-poster'
export const THUMBNAIL_ID_DEFAULT_LINK = 'system/learnpath/element-link-poster'
export const THUMBNAIL_ID_DEFAULT_PDF = 'system/learnpath/element-pdf-poster'
export const THUMBNAIL_ID_DEFAULT_FILE = 'system/learnpath/element-file-poster'
export const THUMBNAIL_ID_PENDING_SCORM =
  'system/learnpath/LearnpathsAddScormDialogEditThumbnailsPending'

export const ELEMENT_QUIZ_POSTER_ICON =
  'system/learnpath/element-quiz-poster-icon'
export const ELEMENT_SCORM_POSTER_ICON =
  'system/learnpath/element-scorm-poster-icon'

export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE'
